import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }

  // TODO: replace by BS stretched-link
  goTo() {
    Turbolinks.visit(this.urlValue)
  }
}
