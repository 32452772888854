import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "product", "filter" ]
  static values = {
    category: { type: String, default: "all" }
  }

  filterProducts() {
    this.categoryValue = event.srcElement.dataset.boutiqueCategoryValue
  }

  categoryValueChanged() {
    if (this.categoryValue == "all") {
      this.showAll()
    } else {
      this.showCurrentCategory()
    }
  }

  showAll() {
    this.productTargets.forEach((el) => {
      el.classList.remove("js-product--unselected")
    })
  }

  showCurrentCategory() {
    this.productTargets.forEach((el) => {
      el.classList.toggle("js-product--unselected", this.productCategory(el) !== this.categoryValue)
    })
    this.filterTargets.forEach((el) => {
      el.classList.toggle("js-filter--active", this.categoryValue == this.productCategory(el))
    })
  }

  productCategory(el) {
    return el.dataset.boutiqueCategoryValue
  }
}
