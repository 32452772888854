import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "section", "slide", "product" ]
  static values = { index: Number }

  indexValueChanged() {
    this.showCurrentSection()
  }

  toggleSection() {
    this.indexValue = event.srcElement.dataset.addProductIndexValue
  }

  showCurrentSection() {
    this.sectionTargets.forEach((element, index) => {
      if (index == this.indexValue) {
        element.classList.add("active")
      } else {
        element.classList.remove("active")
      }
    })
    this.slideTargets.forEach((element, index) => {
      element.hidden = index != this.indexValue
    })
  }

  markSelectedProduct(event) {
    const id = event.target.dataset.productId
    this.productId = id

    this.productTargets.forEach((el, i) => {
      el.classList.toggle("js-product--selected", this.productId == this.retrieveId(el))
    })

    this.slideTargets.forEach((element, index) => {
      var button = element.querySelector("button")
      if (index == this.indexValue) {
        button.classList.remove("d-none")
      } else {
        button.classList.add("d-none")
      }
    })
  }

  get productId() {
    return parseInt(this.data.get("productId"))
  }

  addProduct() {
    const path = "cart/add-to-cart/"
    window.location.href = path + this.productId
  }

  retrieveId(el) {
    return el.dataset.productId
  }

  set productId(value) {
    this.data.set("productId", value)
  }
}
