import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'recipientInput', 'recipient', 'messageInput', 'message' ]

  updateContent(event) {
    event.preventDefault()
    this.recipientTarget.innerText = this.recipientInputTarget.value
    this.messageTarget.innerText = this.formattedMessage(this.messageInputTarget.value)
  }

  formattedMessage(message) {
    return message.replace(/\n\r?/g, '<br />')
  }
}
