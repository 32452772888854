import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  open(e) {
    e.preventDefault()
    this.sourceTarget.style.height = "100%"
  }

  close(e) {
    e.preventDefault()
    this.sourceTarget.style.height = "0%"
  }
}
