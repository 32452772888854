import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'control', 'input', 'background', 'thumbnail' ]
  static values = {
    illustration: { type: String, default: 'default' },
    url: { type: String, default: '/gift_cards/default.jpg' }
  }

  initialize() {
    if (this.controlTarget.checked) {
      this.inputTarget.value = 'default'
    } else {
      this.inputTarget.value = ''
    }
  }

  setIllustration(event) {
    event.preventDefault()
    this.illustrationValue = event.currentTarget.dataset.illustration
  }

  illustrationValueChanged() {
    this.showCurrentIllustration()
  }

  showCurrentIllustration() {
    this.inputTarget.value = this.illustrationValue
    this.urlValue = this.illustrationUrl
    this.backgroundTarget.style.background = 'url(' + this.urlValue + ')'
    this.thumbnailTargets.forEach(thumbnail => {
      if (thumbnail.dataset.illustration === this.illustrationValue) {
        thumbnail.classList.add('active')
      } else {
        thumbnail.classList.remove('active')
      }
    })
  }

  get illustrationUrl() {
    return '/gift_cards/' + this.illustrationValue + '.jpg'
  }
}
