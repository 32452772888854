import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "slide", "control" ]
  static values = {
    index: { type: Number, default: 0 }
  }

  goToSlide() {
    this.indexValue = event.srcElement.dataset.slideshowIndexValue
  }

  indexValueChanged() {
    this.showCurrentSlide()
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      element.hidden = index != this.indexValue
    })
    this.controlTargets.forEach((element, index) => {
      element.classList.toggle("active", this.indexValue == index)
    })
  }
}
